import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApiRequestService } from './shared/api-request.service';
import { FileError } from 'ngxf-uploader';
import { NbToastrService } from '@nebular/theme';
import { appConstants } from './shared/constants';
import { retry } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'upwards-dsa-lead-generation';
  selectedTelemarketer: boolean = false;
  approvalStatusID: number;
  docsToUpload = {
    [appConstants.documetnTypes.telemarketerProof]: {},
  };
  currentDocType: number;
  adminID: number;
  ALL_DOC_TYPES = appConstants.documetnTypes;
  telemarketerDateForm: FormGroup;
  startDate
  endDate
  userResponse

  constructor(
    private apiRequest: ApiRequestService,
    private toastr: NbToastrService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    // console.log('this is the value for userLoggedIn -->>', this.get('user_logged_in'))

    setTimeout(() => {
      if(localStorage.getItem('User-Session-Token') && localStorage.getItem('User-Id') ||localStorage.getItem('user_logged_in')) {
        // this.enachDialogModel.show();
        this.telemarketerEligibility();
        this.getUploadedDocStatus();
        // window.location.reload();
      } else {
        // this.ngOnInit();
      }
     }
      , 1000);

    this.telemarketerDateForm = this.formBuilder.group({
      // Following are the fields of Salaried Employment
      startDate: [undefined, Validators.required],
      endDate: [undefined, Validators.required],
    })

  }

  callEligibility() {
    console.log('Eligibility is called -->>')
  } 
  
  @ViewChild ('enachDialogModel', {static: false}) public enachDialogModel: ModalDirective

  teleMarketerSelected(response) {
    if(response == 'Yes') {
      this.selectedTelemarketer = true;
      this.userResponse = true;
    } else if (response == 'No') {
      this.userResponse = false;
      let payload = {
        "is_telemarketer": this.userResponse,
        // "valid_from_datetime": '',
        // "valid_till_datetime": '',
      }
      this.apiRequest.submitTelemarketerData(payload).subscribe(res => {
        if(res) {
          this.enachDialogModel.hide(); 
        }
      }) 
    }
  }

  getUploadedDocStatus() {
    this.apiRequest.adminDocumentDetails(this.adminID).subscribe(res => {
      console.log('this is the uploaded doc status-->>>', res);
      if (res && res['document_data']) {
        this.setUploadedDocStatus(res['document_data']);
      }
    });
  }

  private setUploadedDocStatus(docUploadedObj: { [key: string]: string }) {
    for (let [docTypeId, status] of Object.entries(docUploadedObj)) {
      if (this.docsToUpload[docTypeId] && appConstants.docUploadSuccessStatuses.includes(status)) {
        this.docsToUpload[docTypeId]['uploaded'] = true;
      }
    }
  }

  showTelemarketerDialog() {
    this.enachDialogModel.show();
  }

  telemarketerEligibility() {

    this.apiRequest.getOrganizationDetails().subscribe(response => {
      console.log('this is the responbse--->>>', response);
      if(response)  {
        this.approvalStatusID = response.approval_status_id
        if(this.approvalStatusID == 1 || this.approvalStatusID == 2 || this.approvalStatusID == 3) {
          this.showTelemarketerDialog();
        }
        // approval status ID 1 == document still not uploaded.
        //document uploaded successfully == 2
        //telemarketer document proof rejected == 3
        // telemarketer document proof approved successfully == 3
        console.log('this is the response', response.approval_status_id)
      }
    })
  }

  get(name: string): any {
    try {
      return JSON.parse(localStorage.getItem(name));
    } catch (err) {
      return localStorage.getItem(name);
    }
  }

  documentUploadInitiated() {
    this.enachDialogModel.hide();
  }

  selectFile(file: File | FileError, docType: number): void {
    if (!(file instanceof File)) {
      this.alertError(file);
      return;
    }
    if (!this.docsToUpload[docType]) {
      this.docsToUpload[docType] = {};
    } else {
      this.docsToUpload[docType]['isUploading'] = false;
      this.docsToUpload[docType]['uploaded'] = false;
    }
    this.currentDocType = docType;
    this.docsToUpload[docType]['file'] = file;

    this.docUploadTask(this.currentDocType);
   
  }

  docUploadTask(docTypeId: number) {
    // console.log(docForm);
    if (!this.docsToUpload[docTypeId] || !this.docsToUpload[docTypeId]['file']) {
      this.toastr.danger('Please select file first');
      return;
    }
    if (this.docsToUpload[docTypeId] && this.docsToUpload[docTypeId]['isUploading']) {
      this.toastr.danger('File upload already in progress');
      return;
    }
    // this['uploaded'] = false;
    this.docsToUpload[docTypeId]['uploaded'] = false;
    let docExtension = '.' + this.docsToUpload[docTypeId]['file']['name'].split('.').pop();
       this.adminID = this.get('User-Id')
      this.apiRequest.getAdminUploadURL(this.adminID, docTypeId, docExtension).subscribe(response => {
        if (response && response['document'] != null && response['documemnt'] !== '') {
          let upUrl = response['document'];
          this.finalUpload(upUrl, docTypeId);
        } else {
          retry(1);
          this.toastr.danger('Error while uploading, Please try again');
        }
      });
  }

  finalUpload(upUrl: string, docTypeId: number) {
    let url = upUrl;
    let reader = new FileReader();
    reader.readAsArrayBuffer(this.docsToUpload[docTypeId]['file']);
    reader.onloadend = (ev) => {
      let base64data = reader.result;
      this.docsToUpload[docTypeId]['isUploading'] = true;
      console.log('this is the data -->>', url , 'base64', base64data, this.docsToUpload[docTypeId])
      this.apiRequest.uploadFileToUrl(url, base64data, this.docsToUpload[docTypeId]).subscribe(response => {
        if (response) {
          this.docsToUpload[docTypeId]['isUploading'] = false;
          this.updateDocUploadStatus(docTypeId);
        }
      })
      //let imgRes = this.agentService.uploadImage(url, base64data);
    };
  }

  updateDocUploadStatus(docTypeId: number) {
    let status = this.docsToUpload[docTypeId]['uploaded'] ? 'file_updation_success' : 'file_creation_success';

      this.apiRequest.adminDocumentUploadStatus(this.adminID, docTypeId, status).subscribe(response => {
        if (response['status_updated']) {
          this.docsToUpload[docTypeId]['uploaded'] = true;
          // this.allReuiredDocUploaded = this.isRequiredDocsUploaded();
          // this.updateCustomerDocumentSubmitState();
        }
      });
  }

  alertError(msg: FileError) {
    switch (msg) {
      case FileError.NumError:
        this.toastr.danger('Number Error');
        break;
      case FileError.SizeError:
        this.toastr.danger('File size issue, max allowed 8 MB');
        break;
      case FileError.TypeError:
        this.toastr.danger('Please select correct file type');
        break;
    }
  }

  submitData() {
    // let payload = {
    //   "valid_from_datetime": this.startDate,
    //   "valid_till_datetime": this.endDate,
    // }

    if(this.userResponse) {
      let payload = {
        "is_telemarketer": this.userResponse,
        "valid_from_datetime": this.startDate,
        "valid_till_datetime": this.endDate,
      }

      this.apiRequest.submitTelemarketerData(payload).subscribe(res => {
        if(res) {
          this.enachDialogModel.hide();
          // this.telemarketerEligibility();
          // this.apiRequest.getOrganizationDetails().subscribe(response => {
          //   console.log('this is the responbse--->>>', response);
          //   if(response)  {
          //     this.approvalStatusID = response.approval_status_id
          //     if(this.approvalStatusID == 1 || this.approvalStatusID == 2 || this.approvalStatusID == 3) {
          //       this.showTelemarketerDialog();
          //     }
          //     // approval status ID 1 == document still not uploaded.
          //     //document uploaded successfully == 2
          //     //telemarketer document proof rejected == 3
          //     // telemarketer document proof approved successfully == 3
          //     console.log('this is the response', response.approval_status_id)
          //   }
          // })
        }
      })
    } else {
      let payload = {
        "is_telemarketer": this.userResponse,
        "valid_from_datetime": this.startDate,
        "valid_till_datetime": this.endDate,
      }
      this.apiRequest.submitTelemarketerData(payload).subscribe(res => {
        if(res) {
          this.enachDialogModel.hide();
          this.telemarketerEligibility();
        }
      })
    }
  }
}
