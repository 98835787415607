import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login-layout',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: []
})
export class LoginLayoutComponent {

  window:any;

  constructor(
    @Inject(DOCUMENT) private _document,
  ) { 
    this.window = this._document.defaultView
  }

  open() {
  }

}
