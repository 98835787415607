import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Associate } from 'src/app/shared/interfaces';
import { ShareService } from '../shared/share.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(this.tokenAvailable());
  authInstance: any;
  isAffiliateUser = false;

  constructor(private router: Router,
    private ngZone: NgZone,
    private shareService: ShareService) {
    this.router.events.subscribe(event => {
      if ((event instanceof NavigationEnd) && window.location.href.includes('affiliate')) {
        this.isAffiliateUser = true;
        this.shareService.isAffiliateUser.emit(true);
      } else {
        this.isAffiliateUser = false;
      }
    })
  }
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  login(user: Associate) {
    // add && user.name && user.email
    if (user.user_bearer_token && user.user_id) {
      this.setUserDataInLocalStorage(user);
      this.loggedIn.next(true);
      // this.routeToCorrectPath('');
      this.router.navigate(['/']);
    }
  }
  logout() {
    this.deleteUserLocalStoageData();
    let loginRoute = '/login';     
    if(localStorage.getItem('Login-URL')) {
      loginRoute = localStorage.getItem('Login-URL');         
    }
    try {
      this.googleSignOut();
    } catch (err) {      
      // this.routeToCorrectPath('login');     
      this.router.navigate([loginRoute]);
    }
  }
  
  setGoogleAuthInstance(instance: any) {
    this.authInstance = instance;
  }

  private googleSignOut() {
    let loginRoute = '/login';
    if(localStorage.getItem('Login-URL')) {
      loginRoute = localStorage.getItem('Login-URL'); 
      localStorage.removeItem('Login-URL');
    }    
    if (this.authInstance) {
      this.authInstance.signOut().then(() => {
        console.log('User signed out.');
        // this.ngZone.run(() => this.routeToCorrectPath('login'));
        this.ngZone.run(() => this.router.navigate([loginRoute]));
      });
    } else {
      // this.ngZone.run(() => this.routeToCorrectPath('login'));
      this.ngZone.run(() => this.router.navigate([loginRoute]));

    }
  }
  setAffiliateTypeUser() {
    this.isAffiliateUser = true;
  }
  isAffilliatedUser() {
    return this.isAffiliateUser;
  }
  routeToCorrectPath(childRoute = '') {
    if (this.isAffiliateUser) {
      this.router.navigate(['affiliate', childRoute])
    } else {
      this.router.navigate(['/' + childRoute])
    }
  }
  private tokenAvailable(): boolean {
    return !!localStorage.getItem('User-Session-Token');
  }
  getSourceId() {
    return localStorage.getItem('Source-Id');
  }
  private setUserDataInLocalStorage(user: Associate) { 
    localStorage.setItem('User-Session-Token','Bearer ' + user.user_bearer_token || '');
    localStorage.setItem('User-Id', user.user_id);
    localStorage.setItem('Name', user.name || '');
    localStorage.setItem('Email', user.email_id || '');
    localStorage.setItem('Source-Id', user.source_id.toString());
  }
  private deleteUserLocalStoageData() {
    localStorage.removeItem('User-Session-Token');
    localStorage.removeItem('User-Id');
    localStorage.removeItem('Name');
    localStorage.removeItem('Email');
    localStorage.removeItem('Source-Id');
    localStorage.removeItem('user_logged_in');
  }
}
